export const adminLteConf = {
    sidebarLeftMenu: [
        {
            label: 'Menu de Navegación',
            separator: true
        },
        {
            label: ' Inicio', route: '/',
            iconClasses: 'fa fa-home', 
            pullRights: [
                { 
                    classes: 'label pull-right bg-green' 
                }
            ]
        },
        { 
            label: ' Usuarios', 
            route: '/usuarios', 
            iconClasses: 'fa fa-user-o', 
            pullRights: [
                { 
                    classes: 'label pull-right bg-green' 
                }
            ] 
        },
        { 
            label: ' Usuarios app', 
            route: '/usuariosA', 
            iconClasses: 'fa fa-users', 
            pullRights: [
                { 
                    classes: 'label pull-right bg-red' 
                }
            ] 
        },
        { 
            label: ' Publicaciones', 
            route: '/publicacion', 
            iconClasses: 'fa fa-newspaper-o', 
            pullRights: [
                { 
                    classes: 'label pull-right bg-red' 
                }
            ] 
        },
        { 
            label: ' Reportes', 
            route: '/publicaciones', 
            iconClasses: 'fa fa-book', 
            pullRights: [
                { 
                    classes: 'label pull-right bg-red' 
                }
            ] 
        },
        { 
            label: ' GPS Tracking', 
            route: '/gps', 
            iconClasses: 'fa fa-map-marker', 
            pullRights: [
                { 
                    classes: 'label pull-right bg-red' 
                }
            ] 
        },
    ]
};